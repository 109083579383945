<template>
  <section class="container">
    <layout-breadcrumb :items="breadcrumbs"></layout-breadcrumb>
      <div class="row">
        <div class="col-md-12 mb-3">
          <h3 class="text-transparencia">
             <!-- <a @click="$router.go(-1)"><i class="fa fa-arrow-left cr-po mr-5" data-toggle="tooltip" data-placement="top" title="Volver"></i></a> -->
            Actividades
          </h3>
        </div>
      </div>
      <div class="row">
        <template v-for="(actividad, index) in actividades">
          <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-3  " :key="index">
            <div class="card card-frame card-owework border hover-shadow-9 transition-hover">
              <a class="fs-13 fw-600 text-center" :href="'detalle/' + actividad.voluntariadoActividadId">
                <img class="img-fluid w-100 rounded cimage-slider" :src="`${filesUrl + actividad.rutaImagenMiniatura}`" :alt="actividad.titulo" />
              </a>
              <div class="card-body p-2 pt-3" style="height:5.3rem !important;overflow-hidden">
                <a class="fs-13 fw-600 anim-link-2 anim-link-2-primary" :href="'detalle/' + actividad.voluntariadoActividadId">
                  <h6 class="card-title fs-15 text-transparencia text-center">{{ actividad.titulo }}</h6>
                </a>
              </div>
              <div class="card-footer text-right pt-0">
                <a class="fs-13 fw-600  anim-link-2 anim-link-2-primary" :href="'detalle/' + actividad.voluntariadoActividadId">Leer más<i class="fa fa-search-plus ml-1"></i></a>
              </div>
            </div>
          </div>
        </template>
      </div>
  </section>
</template>

<script>
import layoutBreadcrumb from "@/layout/layout-breadcrumb";
export default {
  name: "voluntariado-actividades",
  metaInfo: {
    title: "Actividades de voluntariado",
  },
  components: {layoutBreadcrumb},
  data: () => ({
    actividades: {},
  }),
  methods: {
    fetchDatosActividades: async function() {
      await this.$http.get(this.apiRoute("Common", "ListVoluntariadoActividadesAll")).then(function(response) {
        if (!response.body.error) {
          this.actividades = response.body.data;
        }
      });
    },
  },
  mounted: async function() {
    await this.fetchDatosActividades();

    this.breadcrumbs = [
      { name: "Inicio", url: "/home", icon: "fa fa-home", active: false },
      { name: "Voluntariado", url: "/voluntariado", icon: "", active: false },
      { name: "Actividades", url: "", icon: "", active: true },
    ];
  },
};
</script>
